<template>
  <div class="home">
    <h1>我是首页</h1>
    <h2>功能介绍还没来得及写。。。</h2>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  setup() {}
}
</script>
